const WS_ROOT = 'wss://proctoring.ts-technology.kz';

const token = localStorage.getItem('token');

export const socket = new WebSocket(WS_ROOT);

socket.onopen = function(e) {
    console.info("%c[ws:open] Соединение установлено", 'color:#fff;background-color:#407bff');
};

socket.onmessage = function(event) {
    console.log(`%c[ws:message] Данные получены с сервера: ${event.data}`, 'color:#fff;background-color:#407bff');
};

socket.onclose = function(event) {
    if (event.wasClean) {
        console.info(`%c[ws:close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`, 'color:#fff;background-color:red');
    } else {
        console.info('%c[ws:close] Соединение прервано', 'color:#fff;background-color:red');
    }
};

socket.onerror = function(error) {
    console.log(`%c[ws:error] ${error.code}: ${error.reason}`, 'color:#fff;background-color:red');
};