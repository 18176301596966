<template>
  <div class="draggable" :style="{ top: top + 'px', left: left + 'px', width: blockWidth + 'px', height: blockHeight + 'px'}" @mousedown="startDragging">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dragging: false,
      startX: 0,
      startY: 0,
      left: 0,
      top: 0,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      blockWidth: 150,
      blockHeight: 159
    };
  },
  watch: {
    '$store.state.proctoring.webcamSize': function() {
      switch (Number(this.$store.state.proctoring.webcamSize)) {
        case 0:
          this.blockWidth = 150;
          this.blockHeight = 169;
          break;
        case 10:
          this.blockWidth = 200;
          this.blockHeight = 213;
          break;
        case 20:
          this.blockWidth = 250;
          this.blockHeight = 244;
          break;
        case 30:
          this.blockWidth = 300;
          this.blockHeight = 282;
          break;
      }
    }
  },
  methods: {
    startDragging(event) {
      this.dragging = true;
      this.startX = event.clientX - this.left;
      this.startY = event.clientY - this.top;

      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    drag(event) {
      if (this.dragging) {
        let newLeft = event.clientX - this.startX;
        let newTop = event.clientY - this.startY;

        if (newLeft < 0) {
          newLeft = 0;
        } else if (newLeft + this.blockWidth > this.screenWidth) {
          newLeft = this.screenWidth - this.blockWidth;
        }

        if (newTop < 0) {
          newTop = 0;
        } else if (newTop + this.blockHeight > this.screenHeight) {
          newTop = this.screenHeight - this.blockHeight;
        }

        this.left = newLeft;
        this.top = newTop;
      }
    },
    stopDragging() {
      this.dragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDragging);
    }
  }
};
</script>

<style scoped>
.draggable {
  position: absolute;
  cursor: move;
}
</style>