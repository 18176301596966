import * as API from '@/api'

const state = {
  permissionWebCam: false,
  permissionWebContents: false,
  webcamSize: 0,
}

const getters = {}

const mutations = {
  updatePermissionWebCam(state, data) {
    state.permissionWebCam = data;
  },
  updatePermissionWebContents(state, data) {
    state.permissionWebContents = data;
  },
  updateWebcamSize(state, data) {
    state.webcamSize = data;
  }
}


export default {
  state,
  getters,
  mutations,
}