<template>
  <div>
    <nav class="component-navbar main-navbar navbar navbar-expand-lg" style="min-height: 82px">
      <div class="container">
        <div v-if="userQuizId" style="color: gray;margin-top: auto;margin-bottom: auto">Уникальный идентификатор прохождения #{{userQuizId}}</div>

      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'Navbar',
  data () {
    return {
      userQuizId:localStorage.getItem('user_quiz_id'),
      logged: false,
      collapsed: true,
      project_id: null
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === '' || localStorage.getItem('token') === null);
    if (localStorage.getItem('quiz')){
      const { project_id } = JSON.parse(localStorage.getItem('quiz'));
      this.project_id = project_id
    }
  },
  methods: {

    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway () {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
