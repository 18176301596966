<template>
  <div>
    <draggable>
      <el-select
          v-model="value2"
          class="m-2"
          placeholder="Select"
          size="small"
          style="width: 100px"
          @change="updateWebCamSize(value2)"
      >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>

      <div id="webcam" class="hide" style="height: fit-content;">
        <video autoplay="" id="video-player" class="proctoring-video"></video>
      </div>
      <div id="displayMedia" class="hide" style="display: none">
        <video autoplay="" width="400" height="300" id="display-media-player"></video>
        <img src="" alt="" width="640" height="400" id="display-media-screen">
      </div>
    </draggable>
  </div>
</template>

<script>
import Vue from 'vue';
import captureVideoFrame from '../../public/captureVideoFrame';
import AWSS3UploadAshClient from "aws-s3-upload-ash";
import md5 from "md5";
import Draggable from './Draggable.vue';
import { socket } from "../api/ws";

let userMediaStreamObject = {};
let displayMediaStreamObject = {};
export default {
  name: 'Proctoring',
  components: {
    Draggable
  },
  data() {
    return {
      timer1: '',
      timer2: '',
      value2: 0,
      options: [
        {
          value: 0,
          label: '150*159',
        },
        {
          value: 10,
          label: '200*203',
        },
        {
          value: 20,
          label: '250*234',
        },
        {
          value: 30,
          label: '300*272',
        },
      ]
    }
  },
  methods: {
    updateWebCamSize(size) {
      this.$store.commit('updateWebcamSize', size);
    },
    updateWebCam(status) {
      this.$store.commit('updatePermissionWebCam', status);
    },
    updateWebContents(status) {
      this.$store.commit('updatePermissionWebContents', status);
    }
  },
  mounted() {
    let serverDate;
    let updateWebCam = this.updateWebCam
    let updateWebContents = this.updateWebContents
    this.$http.get(API_ROOT + '/api/date')
        .then(res => {
          console.log('res', res)
          serverDate = new Date(res.body.date)
        })
        .catch( err => {
          console.log('err', err)
          serverDate = new Date()
        })

    let quiz = JSON.parse(localStorage.getItem('quiz'));
    if (quiz && quiz.proctoring){
      if(quiz.proctoring == 1) {
        navigator.mediaDevices.getUserMedia({video: true})
            .then(mediaStream => {
              mediaStream.getVideoTracks()[0].onended = function () {
                // window.location.href = '/completed';
                updateWebCam(false)

                if (location.pathname == "/identification" || location.pathname == "/about") {
                  setTimeout( () => {
                    location.reload()
                  }, 1000)
                }

                if (location.pathname.includes('question')) {
                  location.href = "https://1000bala.elumiti.kz/testing"
                }

              };
              this.updateWebCam(true)
              this.userMediaStreamObject = mediaStream
              const videoPlayer = document.querySelector('video#video-player');
              const webcam = document.querySelector('#webcam');
              videoPlayer.srcObject = mediaStream;
              webcam.classList.remove("hidden");
              webcam.classList.add("active");

              const saveUserMedia = function (){
                const cameraTrack = mediaStream.getVideoTracks()[0];
                const cameraImageCapture = new ImageCapture(cameraTrack);
                cameraImageCapture.takePhoto()
                    .then(blob => {
                      save(blob, 'user', 'webcam');
                    })
                    .catch(error => {
                      // console.log(error)
                    });
              }
              const catchInterruption = function () {
                const cameraTrack = mediaStream.getVideoTracks()[0];
                const cameraImageCapture = new ImageCapture(cameraTrack);
                cameraImageCapture.takePhoto()
                    .then(blob => {
                      save(blob, 'user', 'webcam', true);
                    })
                    .catch(error => {
                      // console.log(error);
                    });
              }

              let warningOnblur = this.$t('do_not_minimize_your_browser')
              let attentionTitle = this.$t('attention_title')

              window.onblur = function (){
                Vue.toastr({
                  message: attentionTitle,
                  description: warningOnblur,
                  type: 'warning',
                  duration: 10000
                });
                catchInterruption();
              }
              document.addEventListener("visibilitychange", function() {
                if (document.hidden) {
                  catchInterruption();
                }
              });

              setTimeout(saveUserMedia, 5000);
              setInterval(saveUserMedia, 180000);
            })
            .catch(error => {
              this.updateWebCam(false)
              if (this.userMediaStreamObject) {
                this.userMediaStreamObject.stop();
              }
              Vue.toastr({
                message: this.$t('attentionTitle'),
                description: this.$t('access_to_webcam'),
                type: 'error',
              });

              if (location.pathname == "/identification" || location.pathname == "/about") {
                setTimeout( () => {
                  location.reload()
                }, 1000)
              }

              if (location.pathname.includes('question')) {
                location.href = "https://1000bala.elumiti.kz/testing"
              }

              // this.$router.push({ name: 'about' });
              // window.location.href = '/identification';
              // document.getElementById('permissionDenied').classList.add('active');
              // document.getElementById('webcam').classList.remove('active');
              // document.getElementById('webcam').classList.add('hidden');
              // console.log(error);
            });

        navigator.mediaDevices.getDisplayMedia({
          video: {
            displaySurface: 'monitor'
          }
        })
            .then(mediaStream => {
              mediaStream.getVideoTracks()[0].onended = function () {
                // window.location.href = '/completed';
                // window.location.href = '/about';
                updateWebContents(false)

                if (location.pathname == "/identification" || location.pathname == "/about") {
                  setTimeout( () => {
                    location.reload()
                  }, 1000)
                }

                if (location.pathname.includes('question')) {
                  location.href = "https://1000bala.elumiti.kz/testing"
                }

              };

              if (mediaStream.getTracks()[0].getSettings().displaySurface != "monitor" && location.pathname.includes('question')) {
                location.href = "https://1000bala.elumiti.kz/testing"
              }

              if (mediaStream.getTracks()[0].getSettings().displaySurface == "monitor") {
                this.updateWebContents(true)
              }

              this.displayMediaStreamObject = mediaStream;
              const displaySurface = mediaStream.getTracks()[0].getSettings().deviceId;

              if (displaySurface.substring(0, 12) === 'web-contents') {
                if (this.displayMediaStreamObject) {
                  this.displayMediaStreamObject.stop();
                }
                Vue.toastr({
                  message: this.$t('attention_title'),
                  description: this.$t('access_to_full_screen_recording'),
                  type: 'error'
                });
                // this.$router.push({ name: 'about' });
                // window.location.href = '/about'
                // window.location.href = '/identification';
                // document.getElementById('permissionDenied').classList.add('active');
                // document.getElementById('webcam').classList.remove('active');
                // document.getElementById('webcam').classList.add('hidden');
              }

              document.getElementById('display-media-player').srcObject = mediaStream;
              const saveDeviceMedia = function (){
                const frame = captureVideoFrame('display-media-player', 'png');
                save(frame.blob, 'device','web-contents');
              }

              const catchInterruption = function (){
                const frame = captureVideoFrame('display-media-player', 'png');
                save(frame.blob, 'device', 'web-contents',true);
              }

              let warningOnblur = this.$t('do_not_minimize_your_browser')
              let attentionTitle = this.$t('attention_title')

              window.onblur = function () {
                console.log('window.onblur', document.hidden)
                Vue.toastr({
                  message: attentionTitle,
                  description: warningOnblur,
                  type: 'warning',
                  duration: 10000
                });
                setTimeout(catchInterruption, 500);
                // if (document.hidden) {
                //   setTimeout(catchInterruption, 500);
                // }
              }

              let warningVisibility = this.$t('do_not_switch_to_other_tabs')

              document.addEventListener("visibilitychange", function () {
                console.log('visibilitychange')
                if (document.hidden) {
                  Vue.toastr({
                    message: attentionTitle,
                    description:  warningVisibility,
                    type: 'warning',
                    duration: 10000
                  });
                  setTimeout(catchInterruption, 500);
                }
              });

              setTimeout(saveDeviceMedia, 5000);
              setInterval(saveDeviceMedia, 120000);
            })
            .catch(error => {
              this.updateWebContents(false)
              if (this.displayMediaStreamObject) {
                this.displayMediaStreamObject.stop();
              }
              Vue.toastr({
                message: this.$t('attention_title'),
                description: this.$t('access_to_full_screen_recording'),
                type: 'error',
              });

              if (location.pathname == "/identification" || location.pathname == "/about") {
                setTimeout( () => {
                  location.reload()
                }, 1000)
              }

              if (location.pathname.includes('question')) {
                location.href = "https://1000bala.elumiti.kz/testing"
              }

              // this.$router.push({ name: 'about' });
              // window.location.href = '/about'
              // window.location.href = '/identification';
              // document.getElementById('permissionDenied').classList.add('active');
              // document.getElementById('webcam').classList.remove('active');
              // document.getElementById('webcam').classList.add('hidden');
              // console.log(error);
            });

      }
    }

    function save(blob, type, comment = '', interruption = false) {
      const file = new File([blob], 'msr-' + (new Date).toISOString().replace(/:|\./g, '-') + '.gif', {
        type: 'image/gif'
      });

      let formData = new FormData();
      let filePath = 'interval/';
      if (interruption) filePath = 'interruptions/';
      filePath += type + '/' + file.name;
      let token = '';
      if (localStorage.getItem('quiz-token')) {
        token = localStorage.getItem('quiz-token');
      }
      formData.append('video-filename', filePath);
      formData.append('video-blob', file);
      formData.append('token', token);
      // formData.append('_token', document.getElementsByName('_token')[0].value);
      if (interruption) {
        formData.append('violation_type', 'tab_changed');
      }
      formData.append('comment', comment);

      const filename = new Date().toISOString() + '.gif'
      const userIIN = localStorage.getItem('userIIN')
      const userId = JSON.parse(localStorage.getItem('user_id'));
      const md5hash = md5(userIIN + userId)
      const date = serverDate.toISOString().split('T')[0]
      const pathAWS = `stage_2/${date}/${userIIN}_${userId}_${md5hash}`

      // try {
      //   makeXMLHttpRequest(window.API_ROOT +  '/api/save-image', formData, function () {
      //     const downloadURL = filePath;
      //     console.log('pathAWS', pathAWS)
      //     // console.log('File uploaded to this path:', downloadURL);
      //   });
      // } catch (e) {
      //   console.error(e)
      // }

      console.log('save');

      const quiz = JSON.parse(localStorage.getItem('quiz'));
      const wsBody = {
        event: "report",
        payload: {
          user_id: userId,
          file: `${pathAWS}/${filename}`,
          type: interruption ? "tab_changed" : "planned",
          comment,
          metadata: {
            quiz_id: quiz.id
          }
        }
      };

      socket.send(JSON.stringify(wsBody));

      let awsClient = new AWSS3UploadAshClient({
        bucketName: "lithium-50",
        dirName: pathAWS,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
        s3Url: "https://lithium-50.object.pscloud.io",
      });
      awsClient.uploadFile(
          file,
          file.type,
          undefined,
          filename,
          undefined
      );
    }

    function makeXMLHttpRequest(url, data, callback) {
      let request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          callback();
        }
      };
      request.open('POST', url);
      request.send(data);
    }
  },
  beforeDestroy() {
    let quiz = JSON.parse(localStorage.getItem('quiz'));
    if (quiz && quiz.proctoring) {
      if (quiz.proctoring == 1) {
        if (this.displayMediaStreamObject) {
          this.displayMediaStreamObject.getTracks().forEach(element => {
            element.stop()
          });
        }
        if (this.userMediaStreamObject) {
          this.userMediaStreamObject.getTracks().forEach(element => {
            element.stop()
          });
        }
      }
    }

    socket.close();
  }
}
</script>
