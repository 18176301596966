import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '@/router'

switch (window.location.hostname) {
  case 'localhost':
    window.MYNBALA_API_ROOT = 'https://api1000bala.elumiti.kz'
    window.API_ROOT = 'https://quizapi.elumiti.kz'
    // window.API_ROOT = 'https://quiz-api.elumiti.kz'
    window.CABINET_ROOT = 'https://1000bala.elumiti.kz'
    window.QABILET_ROOT = 'https://qabilet.elumiti.kz'
    break;
  case 'quiz.elumiti.kz':
    window.MYNBALA_API_ROOT = 'https://mynbala-api.elumiti.kz'
    window.API_ROOT = 'https://quizapi.elumiti.kz'
    window.CABINET_ROOT = 'https://1000bala.elumiti.kz'
    window.QABILET_ROOT = 'https://qabilet.elumiti.kz'
    break;
  case 'qz.elbasyacademy.kz':
    window.QUIZ_API_ROOT = 'https://qzapi.elbasyacademy.kz'
    window.API_ROOT = 'https://mynbalaapi.elbasyacademy.kz'
    window.QUIZ_ROOT = 'https://qz.elbasyacademy.kz'
    break;
  default:
    window.MYNBALA_API_ROOT = 'https://api1000bala.elumiti.kz'
    window.API_ROOT = 'https://quizapi.elumiti.kz'
    // window.API_ROOT = 'https://quiz-api.elumiti.kz'
    window.CABINET_ROOT = 'https://1000bala.elumiti.kz'
    window.QABILET_ROOT = 'https://qabilet.elumiti.kz'
}

Vue.use(VueResource)

Vue.http.options.crossOrigin = true
Vue.http.options.xhr = {withCredentials: true}
Vue.http.options.root = API_ROOT
// Vue.http.options.emulateJSON = true

Vue.http.options.progress = function (event) {
  if (event.lengthComputable) {
    NProgress.set(event.loaded / event.total)
  }
}

Vue.http.interceptors.push((request, next) => {
  NProgress.start()

  if (localStorage.getItem('token')) {
    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
  }
  next((response) => {
    NProgress.done()
    if (response.url === window.MYNBALA_API_ROOT + '/api/photo/check') {
      return;
    }
    // if (response.status == 422 || response.status==404) {
    //   Vue.toastr({
    //     message: 'Error',
    //     description: 'Этот тест уже был завершен',
    //     type: 'error'
    //   })
    // }
    // else
      if (response.status >= 400) {
        Vue.toastr({
          message: 'Error',
          description: (response.body.message) ? response.body.message : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
          type: 'error'
        })
      }

    return response
  })
})

export const GetIIN = Vue.resource(API_ROOT + '/api/iin')

export const Auth = Vue.resource(API_ROOT + '/api/login')

export const Logout = Vue.resource(API_ROOT + '/api/logout')

export const SendSMS = Vue.resource(API_ROOT + '/api/sms')
